import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div``;

export default { Layout, InnerContainer };
